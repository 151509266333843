.textWidth {
    width: 60%;
}

@media only screen and (max-width: 768px) {
    .textWidth {
        width: 90%
    }
}

body::-webkit-scrollbar {
    display: none;
}

.videoMobile {
    margin-top: 100px;
}

.videoDesktop {
    position: fixed;
    top     : 0;
}

.videoHomepage {
    width : 100% !important;
    height: auto !important;
}