/*!

=========================================================
* Material Kit PRO React - v1.8.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page                      : https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-tagsinput";

@font-face {
    font-family: Engry;
    src        : local('Engry') url('../fonts/engry.otf') format('opentype');

}

h1,
h2,
h3,
h6 {
    font-family: Engry !important;
}

a,
h4,
h5,
p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}


.blog {
    text-align: justify;

    p {
        font-size: 17px !important;
    }

    img {
        text-align: center;
        width     : 70% !important;
    }

    a {
        color: inherit;
    }

    .wp-block-image {
        text-align: center;
    }
}

.MuiCardMedia-root {
    span {
        height: 100%;

        img {
            height: 100%;
            width : 100%;
        }
    }
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;

    img {
        width: 100%;
    }
}

.react-datepicker-wrapper {
    width: 100%;

    input {
        width        : 100%;
        padding      : 12px 5px;
        border       : 1px solid #d3d3d3;
        border-radius: 5px;
    }
}

@media screen and (max-width: 768px) {
    .blog {
        img {
            width: 100% !important;
        }
    }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #454243 !important;
}

.bookingSection {
    color: #fff;

    .MuiOutlinedInput-notchedOutline {
        border-color: #fff !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff !important;
    }

    .MuiInputBase-input,
    .MuiSelect-select:focus {
        background-color: #fff;
        border-radius   : 4px;
    }

    button {
        margin-bottom: 0;
        color        : #454243;

        &:hover {
            color: #454243;

        }
    }
}


.shake-horizontal {
    -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation        : shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}


@keyframes shake-horizontal {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform        : translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-5px);
        transform        : translateX(-5px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateX(5px);
        transform        : translateX(5px);
    }

    80% {
        -webkit-transform: translateX(3px);
        transform        : translateX(3px);
    }

    90% {
        -webkit-transform: translateX(-3px);
        transform        : translateX(-3px);
    }
}

.customDatePicker {
    .MuiInputBase-input {
        padding: 11px;
    }

    .MuiInput-underline {
        &:after {
            border-bottom: 2px solid #454243;
        }
    }
}

.MuiPickersDay-daySelected {
    background-color: #454243 !important;

    &:hover {
        background-color: #454243 !important;

    }
}

.MuiPickersToolbar-toolbar {
    background-color: #454243 !important;

}

.paddingSlider {
    .slick-slide {
        padding: 5px;
    }
}

.countryDropdown {
    padding      : 10.5px;
    margin-top   : 5px;
    border-radius: 4px;
    width        : 100%;
}

.dialogSubmitButton {
    &:hover {
        background-color: #b87333 !important;
        color           : #fff !important;
    }
}

.StripeCheckout {
    box-shadow: none !important;
    padding   : 10px !important;
    width     : 100%;

    span {
        background-image: none !important;
        box-shadow      : none !important;
        text-shadow     : none !important;
    }
}